import type { AxiosInstance } from 'axios'
import type { GetServerSidePropsContext, NextPageContext } from 'next'

import { getSmartButton } from './services/button'
import type {
  createEmbedPlaylistCollectionFeed,
  createEmbedUserTimelineHashtagFeed,
  createShowroomScheduleFeed,
  getChannelById,
  getMoreFeedItems,
} from './services/feed'
import {
  createEmbedPlaylistFeed,
  createEmbedUserProductSKUFeed,
  createEmbedUserTimelineFeed,
  getChannel,
} from './services/feed'
import type {
  getLiveStreamEvents,
  getLiveStreamMessages,
  getLiveStreamPrivateMessages,
  getLiveStreamStateSnapshot,
} from './services/livestream'
import type {
  getMicrositeByChannel,
  getMicrositeByChannelId,
  getSitemapPagesByChannelId,
  getSitemapVideosByChannel,
} from './services/microsites'
import type {
  checkVideoAccessCode,
  createTriviaGiveawayResult,
  createVideoInteractionResult,
  getHashtag,
  getPlaylistFromCohorts,
  getRemindMe,
  getVideo,
  getVideoEmailEmbed,
} from './services/uncategorized'
import { setupRedaxiosClient } from './setupRedaxiosClient'

type Tail<T extends unknown[]> = T extends [unknown, ...infer R] ? R : never

export type ApiService = ReturnType<typeof createApiService>

/**
 * Creates an API service with the given API host and context. Many API functions
 * are async loaded to reduce bundle size.
 */
export const createApiService = (
  apiHost: string,
  context?: GetServerSidePropsContext | NextPageContext,
  __setupFunc = setupRedaxiosClient,
) => {
  const api: AxiosInstance = __setupFunc(apiHost, context)

  return {
    // Feed (essential, sync loaded)

    getChannel(...args: Tail<Parameters<typeof getChannel>>) {
      return getChannel(api, ...args)
    },
    createEmbedUserTimelineFeed(
      ...args: Tail<Parameters<typeof createEmbedUserTimelineFeed>>
    ) {
      return createEmbedUserTimelineFeed(api, ...args)
    },
    createEmbedPlaylistFeed(
      ...args: Tail<Parameters<typeof createEmbedPlaylistFeed>>
    ) {
      return createEmbedPlaylistFeed(api, ...args)
    },
    createEmbedUserProductSKUFeed(
      ...args: Tail<Parameters<typeof createEmbedUserProductSKUFeed>>
    ) {
      return createEmbedUserProductSKUFeed(api, ...args)
    },

    // Feed (non-essential, async loaded)

    async getChannelById(...args: Tail<Parameters<typeof getChannelById>>) {
      const m = await require('./services/feed')
      return await m.getChannelById(api, ...args)
    },
    async createEmbedUserTimelineHashtagFeed(
      ...args: Tail<Parameters<typeof createEmbedUserTimelineHashtagFeed>>
    ) {
      const m = await import('./services/feed')
      return await m.createEmbedUserTimelineHashtagFeed(api, ...args)
    },
    async createEmbedPlaylistCollectionFeed(
      ...args: Tail<Parameters<typeof createEmbedPlaylistCollectionFeed>>
    ) {
      const m = await import('./services/feed')
      return await m.createEmbedPlaylistCollectionFeed(api, ...args)
    },
    async createShowroomScheduleFeed(
      ...args: Tail<Parameters<typeof createShowroomScheduleFeed>>
    ) {
      const m = await import('./services/feed')
      return await m.createShowroomScheduleFeed(api, ...args)
    },
    async getMoreFeedItems(...args: Tail<Parameters<typeof getMoreFeedItems>>) {
      const m = await import('./services/feed')
      return await m.getMoreFeedItems(api, ...args)
    },

    // Button Wrapper (essential)

    getSmartButton(...args: Tail<Parameters<typeof getSmartButton>>) {
      return getSmartButton(api, ...args)
    },

    // Livestream

    async getLiveStreamMessages(
      ...args: Tail<Parameters<typeof getLiveStreamMessages>>
    ) {
      const m = await import('./services/livestream')
      return await m.getLiveStreamMessages(api, ...args)
    },
    async getLiveStreamPrivateMessages(
      ...args: Tail<Parameters<typeof getLiveStreamPrivateMessages>>
    ) {
      const m = await import('./services/livestream')
      return await m.getLiveStreamPrivateMessages(api, ...args)
    },
    async getLiveStreamEvents(
      ...args: Tail<Parameters<typeof getLiveStreamEvents>>
    ) {
      const m = await import('./services/livestream')
      return await m.getLiveStreamEvents(api, ...args)
    },
    async getLiveStreamStateSnapshot(
      ...args: Tail<Parameters<typeof getLiveStreamStateSnapshot>>
    ) {
      const m = await import('./services/livestream')
      return await m.getLiveStreamStateSnapshot(api, ...args)
    },

    // Microsites

    async getMicrositeByChannel(
      ...args: Tail<Parameters<typeof getMicrositeByChannel>>
    ) {
      const m = await import('./services/microsites')
      return await m.getMicrositeByChannel(api, ...args)
    },
    async getMicrositeByChannelId(
      ...args: Tail<Parameters<typeof getMicrositeByChannelId>>
    ) {
      const m = await import('./services/microsites')
      return await m.getMicrositeByChannelId(api, ...args)
    },
    async getSitemapVideosByChannel(
      ...args: Tail<Parameters<typeof getSitemapVideosByChannel>>
    ) {
      const m = await import('./services/microsites')
      return await m.getSitemapVideosByChannel(api, ...args)
    },
    async getSitemapPagesByChannelId(
      ...args: Tail<Parameters<typeof getSitemapPagesByChannelId>>
    ) {
      const m = await import('./services/microsites')
      return await m.getSitemapPagesByChannelId(api, ...args)
    },

    // Uncategorized

    async getVideo(...args: Tail<Parameters<typeof getVideo>>) {
      const m = await import('./services/uncategorized')
      return await m.getVideo(api, ...args)
    },
    async getHashtag(...args: Tail<Parameters<typeof getHashtag>>) {
      const m = await import('./services/uncategorized')
      return await m.getHashtag(api, ...args)
    },
    async createVideoInteractionResult(
      ...args: Tail<Parameters<typeof createVideoInteractionResult>>
    ) {
      const m = await import('./services/uncategorized')
      return await m.createVideoInteractionResult(api, ...args)
    },
    async createTriviaGiveawayResult(
      ...args: Tail<Parameters<typeof createTriviaGiveawayResult>>
    ) {
      const m = await import('./services/uncategorized')
      return await m.createTriviaGiveawayResult(api, ...args)
    },
    async getRemindMe(...args: Tail<Parameters<typeof getRemindMe>>) {
      const m = await import('./services/uncategorized')
      return await m.getRemindMe(api, ...args)
    },
    async getVideoEmailEmbed(
      ...args: Tail<Parameters<typeof getVideoEmailEmbed>>
    ) {
      const m = await import('./services/uncategorized')
      return await m.getVideoEmailEmbed(api, ...args)
    },
    async checkVideoAccessCode(
      ...args: Tail<Parameters<typeof checkVideoAccessCode>>
    ) {
      const m = await import('./services/uncategorized')
      return await m.checkVideoAccessCode(api, ...args)
    },
    async getPlaylistFromCohorts(
      ...args: Tail<Parameters<typeof getPlaylistFromCohorts>>
    ) {
      const m = await import('./services/uncategorized')
      return await m.getPlaylistFromCohorts(api, ...args)
    },
  }
}
